html{
    scroll-behavior: smooth;
  }

/* ******************************************** Contact Us ********************************************************************* */

.contactform input
{
    margin-top: 60px;
    border: none;
    border-bottom: 2px solid black;
    text-align: center;
    
}
.contactheading h1
{
    text-align: center;
    color:rgb(15, 12, 12);
    margin-top: 30px;
}


/* *******************************************   PageNotFound ************************************************************ */

.spinner
{
   display: flex;
   justify-content: center;
   margin-top: 200px;
}
.spinner1
{
   display: flex;
   justify-content: center;
   margin-top: 30px;
}

/* *********************************************** MyNavbar *************************************************************** */

.navname :hover
{
   color: red;

}

NavLink
{
  margin-left: '100px';
}

/* *********************************************** Project ******************************************************************* */

.projectimg
{
    height: 300px;

}
.projectimg:hover
{
    border: 2px solid gray;
    box-sizing: border-box;
    outline-offset: 2px solid gray;
}

.projectbtn
{
  display: flex;
  justify-content: center;
  background-image: linear-gradient(to right, rgba(255,0,0,1),rgb(250, 247, 247));

}
.projectbtn:hover
{
  background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
  
}

/* ******************************************************* Home **************************************************** */

.home-bg
{
    /* width: 100vw; */
   height: auto; 
   /* background-image: url('../Images/dev1.jpg');  */
   background-color: rgb(247, 246, 244);
   background-size: 100% 100%;
}

.inner-bg
{
  width: 80vw;
  height: auto;
  /* box-shadow: 0px 0px 30px 10px rgb(161, 178, 194); */
  margin-top: 60px;

}
.inner-div
{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ******************************************************* Certificate ********************************************* */


.certificateheading h1
{
    text-align: center;
    color:rgb(22, 20, 20);
    margin-top: 30px;
}
.certificate
{
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.btncss
{
  padding: 5px 25px;
  border-radius:5px 20px ;
}

.btncss:hover
{
  padding: 5px  25px;
  border-radius: 20px 5px ;
}

/* style={{ padding: '5px 25px' , borderRadius:'1px 30px' }} this is a button css for home page  */